import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import { BallTriangle } from 'react-loader-spinner';

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    return "unknown";
}

const ReferralDeepLinking = () => {
    const [seconds, setSeconds] = useState(0)
    const [increaseSeconds, setIncreaseSeconds] = useState(0);
    const code = new URLSearchParams(window.location.search).get('code');
    const redirectApp = (checkIfInstalled) => {
        var deviceOperatingSystem = getMobileOperatingSystem();

        if (checkIfInstalled){
            window.location = `ipkos://invite?code=${code}`;
            return;
        }

        if (deviceOperatingSystem == 'Android'){
            window.location = `https://play.google.com/store/apps/details?id=com.zombiesoup.ipkos&hl=en_US&gl=US&&code=${code}`;                    
        } else if (deviceOperatingSystem == 'iOS') {
            window.location = `itms-apps://itunes.apple.com/app/id1551556823?code=${code}`;    
        }
    }
    useEffect(() => {
        if (increaseSeconds == 0 || seconds > 100) return;
        redirectApp(false);
        setSeconds(seconds + 1)
    }, [increaseSeconds]);

    useEffect(() => {
        var now = new Date().valueOf();
        setInterval(function () {
            setIncreaseSeconds()
            setIncreaseSeconds(new Date());
            if (seconds > 10) return
        }, 2000);
        redirectApp(true);
    }, [])

    
    return (
        <>
            <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#4fa94d"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
            <div style={{marginTop: 50}}>{code}</div>
        </>
    )
}

export default ReferralDeepLinking;